<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="550" min-height="550" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item label="供应商">
            {{bdData.supplierName}}
          </el-form-item>
          <el-form-item label="产品">
            {{bdData.productName}}
          </el-form-item>
          <el-form-item label="车数">
            {{ids.length}}
          </el-form-item>
          <template v-for="(item,index) in assayProduct">
            <el-form-item :prop="item.name" :label="item.label" :key="index">
              <el-input v-model="data[item.name]" :placeholder="item.placeholder" type="number"></el-input>
            </el-form-item>
          </template>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      bdData: {},
      ids: [],
      rules: {},
      loading: false,
      assayProduct: []
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.bdData = this.$utils.clone(data.bdData, true)
      this.name = data.name
      this.ids = data.ids
      this.assayProduct = this.$utils.sortBy(data.assayProduct, [['sort', 'asc']])
      this.getRules()
      this.$nextTick(() => {
        if (this.$refs.form) this.$refs.form.clearValidate()
      })
    },
    getRules() {
      this.rules = {}
      this.assayProduct.forEach(item => {
        item['placeholder'] = `输入${item.label}`
        if (!['g', 'shimo', 'x', 'y'].includes(item.name)) {
          this.rules[item.name] = [{required: true, trigger: 'blur', message: item['placeholder']}]
        }
      })
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            boundIds: this.ids,
            params: this.data
          }
          this.$axios
            .post('/order/assay/create', params)
            .then((res) => {
              if (res) {
                this.$message.success('生成化验单成功')
                this.show = false
                this.$emit('init')
              }
              this.loading = false
            })
            .catch((err) => {
              console.log('生成化验单失败', err)
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>