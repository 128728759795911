<template>
  <div v-if="show" class="drawer">
    <el-drawer destroy-on-close :visible.sync="show" size="800px" :show-close="false">
      <div class="drawer-show">
        <div class="drawer-show-header">
          <div class="drawer-show-header-body">
            <h2 class="fl drawer-show-add-name">
              <a href="javascript:void(0);" @click="copyName" class="copy-a" :data-clipboard-text="name">{{ name }}</a>
            </h2>
            <div class="fr">
              <el-button class="drawer-show-add-close" type="text" icon="el-icon-close" @click="show = false" />
            </div>
          </div>
        </div>
        <div class="drawer-show-body">
          <div style="margin-top: 20px">
            <el-tabs v-model="activeName" type="border-card">
              <el-tab-pane label="基本信息" name="基本信息">
                <div class="drawer-item" v-if="type === '采购'">
                  <p class="drawer-item-name">化验编号</p>
                  {{ data.assayCode }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">车牌号</p>
                  {{ data.carNum }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">业务类型</p>
                  {{ data.type }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">过磅状态</p>
                  {{ data.state }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">公司</p>
                  {{ data.companyName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">发货单位</p>
                  {{ data.supplierName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">收货单位</p>
                  {{ data.customerName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">产品</p>
                  {{ data.productName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">仓库</p>
                  {{ data.stockName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">货位</p>
                  {{ data.locationName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">原发/预装</p>
                  {{ data.primaryWeight }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">毛重</p>
                  {{ data.grossWeight }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">皮重</p>
                  {{ data.tareWeight }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">净重</p>
                  {{ data.netWeight }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">亏吨</p>
                  {{ data.lossWeight }}
                </div>
                <div class="drawer-item" v-if="type === '销售'">
                  <p class="drawer-item-name">发运编号</p>
                  {{ data.orderCode }}
                </div>
                <div class="drawer-item" v-if="type === '销售'">
                  <p class="drawer-item-name">司机名称</p>
                  {{ data.driverName }}
                </div>
                <div class="drawer-item" v-if="type === '销售'">
                  <p class="drawer-item-name">司机手机号</p>
                  {{ data.driverPhone }}
                </div>
                <div class="drawer-item" v-if="type === '销售'">
                  <p class="drawer-item-name">车轴数</p>
                  {{ data.axle }}
                </div>
                <div class="drawer-item" v-if="type === '销售'">
                  <p class="drawer-item-name">排放标准</p>
                  {{ data.standard }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">确认装卸货人员</p>
                  {{ data.confirmUserName }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">创建时间</p>
                  {{ data.assignTime }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">一次时间</p>
                  {{ data.firstTime }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">确认装卸货时间</p>
                  {{ data.confirmTime }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">二次时间</p>
                  {{ data.secondTime }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">出厂时间</p>
                  {{ data.outTime }}
                </div>
                <div class="drawer-item">
                  <p class="drawer-item-name">备注</p>
                  {{ data.remark }}
                </div>
                <vxe-table align="center" :data="data.weighRecordDtoList">
                  <template #empty>
                    <el-empty></el-empty>
                  </template>
                  <vxe-table-column field="weighAddress" title="过磅地址" width="100"></vxe-table-column>
                  <vxe-table-column title="过磅图片" min-width="715">
                    <template slot-scope="scope">
                      <div
                        v-if="(scope.row.photoUrlList && scope.row.photoUrlList.length == 0) || !scope.row.photoUrlList">
                        <img :src="noImg" class="pic" width="160" height="90" />
                      </div>
                      <div v-else>
                        <viewer :images="scope.row.photoUrlList">
                          <img v-for="src in scope.row.photoUrlList" :src="src" :key="src" width="160" height="90" />
                        </viewer>
                      </div>
                    </template>
                  </vxe-table-column>
                </vxe-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      data: {},
      activeName: '基本信息',
      name: '',
    }
  },
  methods: {
    display(data) {
      this.name = data.name
      this.data = data.data
      this.show = true
      this.activeName = '基本信息'
    },
    copyName() {
      let clipboard = new this.Clipboard('.copy-a')
      clipboard.on('success', () => {
        this.$message.success('复制成功')
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        this.$message.error('该浏览器不支持自动复制')
        clipboard.destroy()
      })
    },
  },
}
</script>

