<template>
    <div class="base-container">
        <vxe-toolbar perfect size="mini" style="height: 90px;">
            <template v-slot:buttons>
                <div class="cf table-header-padding" style="height: 100%;">
                    <div class="fl" style="width:85%">
                        <el-radio-group @change="search={},searchInit()" size="mini" v-model="type"
                                        style="margin-right: 10px">
                            <el-radio-button label="Purchase">采购</el-radio-button>
                            <el-radio-button label="Sell">销售</el-radio-button>
                        </el-radio-group>
                        <el-input v-model="search.code" clearable placeholder="输入磅单编号" size="mini"
                                  style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                        </el-input>
                        <el-input v-model="search.carCode" clearable placeholder="输入车牌号" size="mini"
                                  style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                        </el-input>
                        <el-select v-model="search.state" filterable clearable placeholder="选择过磅状态" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="item in stateList" :key="item.value" :label="item.label"
                                       :value="item.value">
                            </el-option>
                        </el-select>
                        <el-select v-model="search.companyId" clearable filterable placeholder="选择公司" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in company" :key="index" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <template v-if="type === 'Purchase'">
                            <el-select v-model="search.supplierId" clearable filterable placeholder="选择发货单位" size="mini"
                                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                                <el-option v-for="(item, index) in supplier" :key="index" :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select v-model="search.customerId" clearable filterable placeholder="选择收货单位" size="mini"
                                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                                <el-option v-for="(item, index) in company" :key="index" :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                        <template v-if="type === 'Sell'">
                            <el-select v-model="search.supplierId" clearable filterable placeholder="选择发货单位" size="mini"
                                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                                <el-option v-for="(item, index) in company" :key="index" :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                            <el-select v-model="search.customerId" clearable filterable placeholder="选择收货单位" size="mini"
                                       style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                                <el-option v-for="(item, index) in customer" :key="index" :label="item.name"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                        <el-select v-model="search.productId" clearable filterable placeholder="选择产品" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in product" :key="index" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="search.stockId" clearable filterable placeholder="选择仓库" size="mini"
                                   style="width: 120px; margin: 0 10px 5px 0" @change="searchInit()">
                            <el-option v-for="(item, index) in spaceName" :key="index" :label="item.spaceName"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                        <el-date-picker @change="dateValue(search.value)" v-model="search.value" type="datetimerange"
                                        size="mini"
                                        value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                        :default-time="['00:00:00', '23:59:59']" placeholder="选择日期时间"
                                        class="el-daterange-picker-radius"
                                        style="width: 280px">
                        </el-date-picker>
                    </div>
                    <div class="fr">
                        <el-tooltip class="item" effect="dark" content="查询" placement="top">
                            <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="清空" placement="top">
                            <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="导出" placement="top">
                            <el-button @click="exportDataEvent()" size="mini" class="el-icon-download"
                                       circle></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="打印" placement="top">
                            <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
                            </el-button>
                        </el-tooltip>
                    </div>
                </div>
            </template>
        </vxe-toolbar>
        <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
                   highlight-hover-row auto-resize size="small" ref="bound" resizable row-id="id" v-loading="loading"
                   element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow
                   show-overflow
                   @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
            <template #empty>
                <el-empty></el-empty>
            </template>
            <vxe-table-column type="checkbox" width="45" fixed="left" align="center" key="1"></vxe-table-column>
            <vxe-table-column field="type" min-width="80" title="业务类型" key="2"></vxe-table-column>
            <vxe-table-column field="assayCode" min-width="180" title="化验编号" key="3"></vxe-table-column>
            <vxe-table-column field="code" min-width="180" title="磅单编号" key="4"></vxe-table-column>
            <vxe-table-column field="carNum" min-width="120" title="车牌号" key="5"></vxe-table-column>
            <vxe-table-column field="companyName" min-width="150" title="公司" key="6"></vxe-table-column>
            <vxe-table-column field="supplierName" min-width="150" title="发货单位" key="7"></vxe-table-column>
            <vxe-table-column field="customerName" min-width="150" title="收货单位" key="8"></vxe-table-column>
            <vxe-table-column field="productName" min-width="100" title="产品" key="9"></vxe-table-column>
            <vxe-table-column field="stockName" min-width="100" title="仓库" key="10"></vxe-table-column>
            <vxe-table-column field="locationName" min-width="80" title="货位" key="11"></vxe-table-column>
            <vxe-table-column field="state" min-width="100" title="过磅状态" key="27"></vxe-table-column>
            <vxe-table-column field="primaryWeight" min-width="80" title="原发/预装" key="12"></vxe-table-column>
            <vxe-table-column field="grossWeight" min-width="70" title="毛重" key="13"></vxe-table-column>
            <vxe-table-column field="tareWeight" min-width="70" title="皮重" key="14"></vxe-table-column>
            <vxe-table-column field="netWeight" min-width="70" title="净重" key="15"></vxe-table-column>
            <vxe-table-column field="assignTime" min-width="200" title="创建时间" key="16"></vxe-table-column>
            <vxe-table-column field="firstTime" min-width="200" title="一次时间" key="17"></vxe-table-column>
            <vxe-table-column field="confirmTime" min-width="200" title="确认装卸货时间" key="31"></vxe-table-column>
            <vxe-table-column field="secondTime" min-width="200" title="二次时间" key="18"></vxe-table-column>
            <vxe-table-column field="outTime" min-width="200" title="出厂时间" key="19"></vxe-table-column>
            <vxe-table-column field="confirmUserName" min-width="200" title="确认装卸货人员" key="32"></vxe-table-column>
            <template v-if="type === 'Sell'">
                <vxe-table-column field="orderCode" min-width="120" title="发运编号" key="20"></vxe-table-column>
                <vxe-table-column field="driverName" min-width="100" title="司机名称" key="21"></vxe-table-column>
                <vxe-table-column field="driverPhone" min-width="120" title="司机手机号" key="22"></vxe-table-column>
                <vxe-table-column field="axle" min-width="100" title="车轴数" key="23"></vxe-table-column>
                <vxe-table-column field="standard" min-width="100" title="排放标准" key="24"></vxe-table-column>
            </template>
            <vxe-table-column field="remark" min-width="150" title="备注" key="25"></vxe-table-column>
            <vxe-table-column fixed="right" align="center" width="240" title="操作" key="26">
                <template slot-scope="scope">
                    <el-button type="text" size="mini" @click="getCheck(scope.row)">查看</el-button>
                    <el-button size="mini" type="text" @click="getExamine(scope.row)"
                               v-if="['未审核'].includes(scope.row.state)">审核
                    </el-button>
                    <el-button size="mini" type="text" @click="getRefuse(scope.row)"
                               v-if="['未审核'].includes(scope.row.state)">拒绝
                    </el-button>
                    <el-button size="mini" type="text" @click="getBack(scope.row)"
                               v-if="!['未进厂', '未审核', '拒绝'].includes(scope.row.state)">回退
                    </el-button>
                    <el-button size="mini" type="text" @click="getBound(scope.row)"
                               v-if="['未进厂', '已进厂', '确认装卸货'].includes(scope.row.state)">手工过磅
                    </el-button>
                    <el-button size="mini" type="text" @click="getConfirm(scope.row)"
                               v-if="['一次过磅'].includes(scope.row.state)">确认装卸货
                    </el-button>
                    <el-button size="mini" type="text" @click="getConfirmWeight(scope.row)"
                               v-if="['确认装卸货'].includes(scope.row.state) && !['', null, undefinen].includes(scope.row.secondTime) && type === 'Sell'">确认过磅</el-button>
                    <el-button type="text" size="mini" @click="print(scope.row)"
                               v-if="['二次过磅', '出厂'].includes(scope.row.state)">补打
                    </el-button>
                    <el-button type="text" size="mini" @click="getDelete(scope.row)">
                        删除
                    </el-button>
                </template>
            </vxe-table-column>
        </vxe-table>
        <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
                   :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
                   :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                   :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
            <template v-slot:left>
                <div class="fl footer-check">
                    <p>
                        已选
                        <b style="font-size: 10px">{{ checkLength }}</b> 条
                    </p>
                </div>
                <el-button type="primary" size="mini" @click="getAssay()">生成化验单</el-button>
            </template>
        </vxe-pager>
        <check ref="check"/>
        <assay ref="assay" @init="init"/>
    </div>
</template>

<script>
import check from './components/check'
import assay from './components/assay'
import {mapGetters} from 'vuex'

export default {
    name:'bound',
    data() {
        return {
            data:[],
            tableHeight:window.innerHeight - 230 + 'px',
            allAlign:'center',
            loading:false,
            checkBox:[],
            checkLength:0,
            page:{
                pageSize:20,
                currentPage:1,
                totalResult:0,
            },
            type:'Purchase',
            isClose:'0',
            search:{},
            isNull:['', null, undefined],
            stateList:[
                {value:'NoPass', label:'未审核'},
                {value:'Refuse', label:'拒绝'},
                {value:'NoEnter', label:'未进厂'},
                {value:'Enter', label:'已进厂'},
                {value:'Confirm', label:'确认装卸货'},
                {value:'First', label:'一次过磅'},
                {value:'Second', label:'二次过磅'},
                {value:'Out', label:'出厂'},
            ],
            businessTypeList:[
                {value:'Purchase', label:'采购'},
                {value:'Sell', label:'销售'},
            ],
            timeInterval: null
        }
    },
    computed:{
        ...mapGetters(['product', 'customer', 'company', 'supplier', 'spaceName']),
    },
    created() {
        this.init()
        this.$store.dispatch('getProduct')
        this.$store.dispatch('getCustomer')
        this.$store.dispatch('getCompany')
        this.$store.dispatch('getSupplier')
        this.$store.dispatch('getProductType')
        this.$store.dispatch('getSpaceName')
    },
    components:{check, assay},
    mounted() {
        //监听元素变化
        window.onresize = () => {
            if (this.$refs.bound) {
                this.tableHeight = window.innerHeight - this.$refs.bound.$el.offsetTop - 62 + 'px'
            }
        }
        this.timeInterval = setInterval(() => {
            this.init()
        }, 5000)
    },
    beforeDestroy() {
        clearInterval(this.timeInterval)
    },
    methods:{
        init() {
            this.loading = false
            const params = {
                params:{
                    pageNum:this.page.currentPage,
                    pageSize:this.page.pageSize,
                    type:this.type,
                },
            }
            if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
            if (!this.isNull.includes(this.search.carCode)) params.params.carCode = this.search.carCode
            if (!this.isNull.includes(this.search.productId)) params.params.productId = this.search.productId
            if (!this.isNull.includes(this.search.customerId)) params.params.customerId = this.search.customerId
            if (!this.isNull.includes(this.search.supplierId)) params.params.supplierId = this.search.supplierId
            if (!this.isNull.includes(this.search.companyId)) params.params.companyId = this.search.companyId
            if (!this.isNull.includes(this.search.stockId)) params.params.stockId = this.search.stockId
            if (!this.isNull.includes(this.search.state)) params.params.state = this.search.state
            if (!['', null, undefined].includes(this.search.value)) {
                params.params.startDate = this.search.startDate
                params.params.endDate = this.search.endDate
            }
            this.$axios
                .get('/order/bound/page', params)
                .then((res) => {
                    if (res) {
                        const data = res.data.data
                        this.data = data.list
                        this.page.currentPage = data.pageNum
                        this.page.totalResult = data.total
                        this.checkBox = []
                        this.checkLength = 0
                    }
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },
        exportDataEvent() {
            this.$refs.bound.openExport()
        },
        printDataEvent() {
            this.$refs.bound.openPrint()
        },
        print(row) {
            this.$axios.get(`/order/bound/print/${row.id}`).then((res) => {
                if (res) this.$message.success('补打成功')
            })
        },
        getCheck(row) {
            this.$axios.get(`/order/bound/detail/${row.id}`).then((res) => {
                if (res) {
                    let data = {
                        data:res.data.data,
                        name:res.data.data.code,
                    }
                    this.$refs.check.display(data)
                }
            })
        },
        /** 选择条数 */
        changeBoxChange() {
            this.checkBox = this.$refs.bound.getCheckboxRecords()
            this.checkLength = this.$refs.bound.getCheckboxRecords().length
        },
        /** 条数更改 */
        handlePageChange({currentPage, pageSize}) {
            const _this = this
            _this.page.currentPage = currentPage
            _this.page.pageSize = pageSize
            _this.init()
        },
        searchInit() {
            this.page.currentPage = 1
            this.init()
        },
        getBack(row) {
            this.$confirm(`确定回退该条数据吗？`, '提示', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/bound/back/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`回退成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },
        getDelete(row) {
            this.$confirm(`确定删除该条数据吗？`, '提示', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/bound/delete/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`删除成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },
        getExamine(row) {
            this.$confirm(`确定审核该条数据吗？`, '提示', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/bound/examine/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`审核成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },
        getConfirmWeight(row) {
            this.$confirm(`确定确认过磅吗？`, '提示', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/bound/confirmWeight/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`确认过磅成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },
        getConfirm(row) {
            this.$confirm(`确定确认装卸货吗？`, '提示', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/bound/confirm/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`确认装卸货成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },
        getRefuse(row) {
            this.$confirm(`确定拒绝该条数据吗？`, '提示', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                type:'warning',
            })
                .then(() => {
                    this.$axios.get(`/order/bound/refuse/${row.id}`).then((res) => {
                        if (res) {
                            this.init()
                            this.$message.success(`拒绝成功`)
                        }
                    })
                })
                .catch(() => {
                })
        },

        getBound(row) {
            this.$prompt('请输入过磅数量', {
                confirmButtonText:'确定',
                cancelButtonText:'取消',
                inputErrorMessage:'请输入过磅重量(数字)!',
                inputPattern:/^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
            }).then(({value}) => {
                const data = {
                    uuid:this.$utils.now(),
                    vehicleNumber:row.carNum,
                    tenantId:1,
                    weighAddress:'永利磅房',
                    weight:value,
                    inout:'in'
                }
                this.$axios.post('/order/internal/bangDan/weighRecord/create/1', data).then(() => {
                    this.$message.success('手工过磅成功')
                    this.init()
                })
            }).catch(() => {
            })
        },
        dateValue(value) {
            if (value) {
                this.$set(this.search, 'startDate', value[0])
                this.$set(this.search, 'endDate', value[1])
            }
        },
        getAssay() {
            const ids = this.checkBox.map((item) => item.id)
            let isAssay = true
            if (this.checkLength === 0) {
                this.$message.warning('请选择需要化验的数据')
                isAssay = false
            } else {
                if (!this.$utils.every(this.checkBox, item => item.productId === this.checkBox[0].productId)) {
                    this.$message.warning('选择需要化验的数据中产品必须一致')
                    isAssay = false
                } else if (!this.$utils.every(this.checkBox, item => item.companyId === this.checkBox[0].companyId)) {
                    this.$message.warning('选择需要化验的数据中公司必须一致')
                    isAssay = false
                } else if (!this.$utils.every(this.checkBox, item => item.customerId === this.checkBox[0].customerId)) {
                    this.$message.warning('选择需要化验的数据中收货单位必须一致')
                    isAssay = false
                } else if (!this.$utils.every(this.checkBox, item => item.supplierId === this.checkBox[0].supplierId)) {
                    this.$message.warning('选择需要化验的数据中发货单位必须一致')
                    isAssay = false
                } else if (!this.$utils.every(this.checkBox, item => this.isNull.includes(item.assayCode))) {
                    this.$message.warning('选择需要化验的数据中已有生成化验单的数据')
                    isAssay = false
                }
            }
            if (isAssay) {
                this.$axios.get(`/order/assay/item/product/${this.checkBox[0].productId}`).then(res => {
                    if (res) {
                        const data = {
                            data:{},
                            name:'生成化验单',
                            ids:ids,
                            bdData:this.checkBox[0],
                            assayProduct:res.data.data
                        }
                        this.$refs.assay.display(data)
                    }
                })
            }
        }
    },
}
</script>

<style scoped></style>
